import Cards from "./Cards/Cards";

function App() {
  return (
    <div className=" flex min-h-screen flex-col justify-between bg-gradient-to-br from-[#6c9c2c] to-[#006692]">
      <div className="container mx-auto  flex flex-col items-center justify-around space-y-10 py-12 px-8 text-center text-white sm:py-24 ">
        <div className=" space-y-8">
          <h1 className="mx-auto max-w-screen-md  text-2xl font-bold sm:text-5xl  ">
            Thanks for reaching the leader in the smart parking industry!
          </h1>
          <h4 className="mx-auto max-w-screen-md text-lg  font-light   ">
            We serve any and all parking and mobility customers across North
            America. Our goal is to provide the highest possible level of{" "}
            <strong>expertise and support</strong>, to protect and enhance our
            customers’ investment. Head to the specific website you are
            interested into:
          </h4>
        </div>

        <Cards />
      </div>
      <div className=" p-2 text-center text-xs text-white text-opacity-70 ">
        HUB Parking Technology - a Business Unit of the FAAC Group EU VAT
        IT03820731200© FAAC SpA 2022 - All rights reserved
      </div>
    </div>
  );
}

export default App;
