import React from "react";
import tiba from "./tiba.png";
import logo from "./logo.png";

const Cards = () => {
  const hubText =
    "Drive your parking asset into the future with HUB solutions.";
  const tibaText =
    "TIBA is fast. Flexible. Credible. Discover the #engagedparkingexperience";
  return (
    <div className="  grid max-w-screen-md  items-center justify-center gap-10 md:grid-cols-2 md:grid-rows-1 ">
      <Card
        srcLogo={logo}
        color={"bg-[#6c9c2c]"}
        text={hubText}
        decoration={"decoration-[#6c9c2c]"}
        link={"https://www.hubparking.com/"}
        outline={"outline-[#002d45]"}
      />
      <Card
        srcLogo={tiba}
        color={"bg-[#14c0f4]"}
        text={tibaText}
        decoration={"decoration-[#14c0f4]"}
        link={"https://tibaparking.com/"}
        outline={"outline-[#14c0f4]"}
      />
    </div>
  );
};

export default Cards;

const Card = ({ text, srcLogo, link, color, decoration, outline }) => {
  return (
    <a
      href={link}
      className={` flex outline-4  ${outline}  h-full cursor-pointer flex-col items-center justify-between space-y-10   bg-white px-4 py-10 text-slate-800 hover:outline`}
    >
      <div className=" space-y-10">
        <img src={srcLogo} alt="logo" className="px-12"></img>
        <hr className={` mx-auto h-1  w-2/5 border-0 ${color}`}></hr>
      </div>

      <p className="py-4 font-light text-slate-700">{text}</p>
      <a
        className={`font-bold  underline decoration-4 underline-offset-4 ${decoration}`}
        href={link}
      >
        VISIT WEBSITE
      </a>
    </a>
  );
};
